var Tindie = (function(my) {
    my.track_funnel_event = function(funnel_name) {
       var tracker = function() {
           name = $(this).prop('id').slice(3);
           if (name && this.value && (window.ga && ga.create)) {
                ga('send', 'pageview', '/funnel/' + funnel_name + '/' + name);
           }
       };
       return tracker;
    };

    my.track_form_funnel = function(funnel_name) {
        $(document).on('blur', 'input,select,textarea', my.track_funnel_event(funnel_name));
        $(".qq-uploader-selector").click(function() {
            if (this.value && (window.ga && ga.create)) {
                ga('send', 'pageview', funnel_name + 'upload');
            }
        });
    };

    return my;
})(Tindie || {});


$(function() {
    /// track registration modal views
    $('#signin-tabs a[href="#register"]').click(function(e) {
        if(window.ga && ga.create){
            ga('send', 'pageview', '/accounts/signup/');
            Tindie.track_form_funnel('signup');
        }
    });
});
