tindie.validation = (function(t, $) {
	ko.validation = ko.validation || {};
	ko.validation.rules = ko.validation.rules || {};
	//creates a span next to the @element with the specified error class
	ko.validation.insertValidationMessage = function(element) {
		var span = document.createElement('SPAN'),
			parent = $(element).parent().closest(".input-group"),
			utils = ko.validation.utils;
		span.style.fontWeight = 'bold';
		span.className = utils.getConfigOptions(element).errorMessageClass;
		if (parent.length > 0) {
			utils.insertAfter(parent[0], span);
		} else {
			utils.insertAfter(element, span);
		}
		return span;
	}
	t.checkForServerErrors = function(view) {
		$('form span[id^="error_"]').remove();
		if (typeof(formErrors) !== 'undefined') {
			var errorKeys = Object.keys(formErrors);
			for (var idx = 0; idx < errorKeys.length; idx++) {
				var key = errorKeys[idx];
				var errorMessage = formErrors[key][0]["message"];
				var bindData = $('#id_' + key).data('bind').split(',').reduce(function(acc, current, idx) {
					var boundInfo = current.trim().split(":");
					if (boundInfo[0] == "value" || boundInfo[0] == "selectedOptions") {
						acc = boundInfo[1].trim();
					}
					return acc;
				}, "");
				view[bindData].setError(errorMessage);
			}
		}
	}
	t.vatRegExs = {
		AT: '^U[0-9]{8}$',
		BE: '^(0[0-9]{9}|[0-9]{10})$',
		BG: '^[0-9]{9,10}$',
		CY: '^[0-9]{8}[A-Z]$',
		CZ: '^[0-9]{8,10}$',
		DE: '^[0-9]{9}$',
		DK: '^[0-9]{8}$',
		EE: '^[0-9]{9}$',
		//greece
		EL: '^[0-9]{9}$',
		ES: '^[0-9A-Z][0-9]{7}[0-9A-Z]$',
		FI: '^[0-9]{8}$',
		FR: '^[0-9A-HJ-NP-Z]{2}[0-9]{9}$',
		GB: '^([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$',
		HR: '^[0-9]{11}$',
		HU: '^[0-9]{8}$',
		IE: '^[0-9]([A-Z][0-9]{5}|[0-9]{6})([0-9]|[A-Z])[A-Z]$',
		IT: '^[0-9]{11}$',
		LT: '^([0-9]{9}|[0-9]{12})$',
		LU: '^[0-9]{8}$',
		LV: '^[0-9]{11}$',
		MT: '^[0-9]{8}$',
		NL: '^[0-9]{9}B([0-9]{2}|O2)$',
		PT: '^[0-9]{9}$',
		PL: '^[0-9]{10}$',
		RO: '^[0-9]{2,10}$',
		SE: '^[0-9]{12}$',
		SI: '^[0-9]{8}$',
		SK: '^[0-9]{10}$',
	};
	ko.validation.rules.areSame = {
		getValue: function(o) {
			return (typeof o === 'function' ? o() : o);
		},
		validator: function(val, otherField) {
			return val === this.getValue(otherField);
		},
		message: 'The fields must have the same value'
	};
	ko.validation.rules.uriSlug = {
		getValue: function(o) {
			return (typeof o === 'function' ? o() : o);
		},
		validator: function(val) {
			if (ko.validation.utils.isEmptyVal(val)) {
				return true;
			}
			var regex = /[ ,./!@#$%^&*()=+]/g;
			return !val.match(regex);
		},
		message: 'Invalid slug. Please do not use spaces, allowed special characters are: - or _'
	};
	if (ko.validation.init) {
		ko.validation.init({
			errorElementClass: 'has-error',
			errorMessageClass: 'help-block',
			decorateInputElement: true
		});
	}
	ko.validation.registerExtenders();
	return t;
})(tindie.validation || {}, jQuery);