tindie.ajax = (function(t, $) {
	t.lock = ko.observable(false);
	t.missingMessage = 'Looks like this page is out of date. Reloading to get the current status.';
	t.maintenanceMessage = 'Oops. The server is busy, try again in a couple of minutes.';
	t.errorMessage = 'An unexpected error was encountered. Try again in a couple of minutes or reload the page.';
	var setup = function() {
		var csrftoken = $.cookie('csrftoken');

		function csrfSafeMethod(method) {
			// these HTTP methods do not require CSRF protection
			return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
		}
		$.ajaxSetup({
			crossDomain: false, // obviates need for sameOrigin test
			beforeSend: function(xhr, settings) {
				if (!csrfSafeMethod(settings.type)) {
					xhr.setRequestHeader("X-CSRFToken", csrftoken);
				}
			}
		});
	};
	t.notifyMessageFadeOut = function(notification, delay) {
		$.pnotify({
			text: notification.message,
			type: notification.extra_tags || 'info',
			hide: true,
			delay: delay || 8000
		});
	}
	t.notify_message = function(notification) {
		$.pnotify({
			text: notification.message,
			type: notification.extra_tags || 'info',
			hide: false
		});
	}
	t.show_notifications = function(notifications) {
		$.each(notifications, function(index, notification) {
			t.notify_message(notification);
		});
	};
	t.default_ajax_error_handler = function(jqXHR, statusText, errorThrown) {
		var status = jqXHR == null ? null : jqXHR.status;
		switch (status) {
			case 404:
				// Show error message instead?
				t.notify_message({
					message: t.missingMessage,
					type: 'error'
				});
				setTimeout(function() {
					// wait a second for a message before reloading.
					window.location.reload();
				}, 1000);
				t.lock(false);
				break;
			case 503:
				// maintenance mode
				t.notify_message({
					message: t.maintenanceMessage,
					type: 'error'
				});
				t.lock(false);
				break;
			default:
				// 500 error, timeout, abort, etc
				t.notify_message({
					message: t.errorMessage,
					type: 'error'
				});
				t.lock(false);
				if (typeof(newrelic) !== 'undefined') {
					if (errorThrown.hasOwnProperty("message")) {
						var err = new Error('Unexpected AJAX error: ' + errorThrown.message);
					} else {
						var err = new Error('Unexpected AJAX error: ' + errorThrown);
					}
					newrelic.noticeError(err);
				}
		}
	}
	update_viewmodel = function(data, target) {
		$.each(data, function(key, value) {
			//somehow handle mapping? but this might be going away
			if (target[key]) {
				target[key](value);
			}
		});
	};
	t.ajax_update_viewmodel = function(url, data, view, errors, callback) {
		/* a convenience function for creating an ajax request
		 * that updates the knockout views and handles failures
		 * returns a deferred that can attach extra callback functions
		 * through .done(), .fail() or .always()
		 */
		setup();
		if (t.lock()) {
			// doesn't this just discard the update? XXX
			return $.Deferred();
		}
		return $.ajax({
			url: url,
			type: 'POST',
			dataType: 'json',
			data: data
		}).fail(t.default_ajax_error_handler).done(function(result) {
			t.lock(true);
			if (result.valid) {
				if (typeof(callback) == 'function') {
					callback(result);
				} else {
					update_viewmodel(result, view);
				}
			} else if (errors) {
				if (typeof(errors) == 'function') {
					errors(result.errors);
				} else {
					update_viewmodel(result.errors, errors);
				}
			}
			if (result.django_messages) {
				t.show_notifications(result.django_messages);
			}
			if (result.valid == false && result.redirect_url) {
				window.location.href = result.redirect_url;
			}
			t.lock(false);
			if (view['waiting']) {
				view.waiting(false);
			}
		});
	};
	return t;
})(tindie.ajax || {}, jQuery);