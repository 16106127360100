tindie.search = (function(t, $) {
	t.initSearch = function() {
		$('#search-tindie').autocomplete({
			hint: false
		}, [{
			source: $.fn.autocomplete.sources.hits(stores, {
				hitsPerPage: 3
			}),
			displayKey: 'display_name',
			templates: {
				header: '<div class="aa-category">Stores</div>',
				suggestion: function(hit) {
					html_result = '<a href="' + hit.store_url + '">' + hit.display_name + '</a>';
					return html_result;
				}
			}
		}, {
			source: $.fn.autocomplete.sources.hits(categories, {
				hitsPerPage: 3
			}),
			displayKey: 'name',
			templates: {
				header: '<div class="aa-category">Categories</div>',
				suggestion: function(hit) {
					html_result = '<a href="' + hit.tindie_url + '">' + hit._highlightResult.name.value + ' ' + '</a>';
					return html_result;
				}
			}
		}, {
			source: $.fn.autocomplete.sources.hits(products, {
				hitsPerPage: 15
			}),
			displayKey: 'name',
			templates: {
				header: '<div class="aa-category">Products</div>',
				suggestion: function(hit) {
					if (hit._highlightResult.title.value.length > 60) {
						html_result = '<a href="' + hit.tindie_url + '">' + hit._highlightResult.title.value.substring(0, 60) + '...' + '</a>';
					} else {
						html_result = '<a href="' + hit.tindie_url + '">' + hit._highlightResult.title.value + ' ' + '</a>';
					}
					return html_result;
				}
			}
		}]);
		// search bar tracking
		var searchTimer;
		var searchTrack = function() {
			var term = $('#search-tindie').val();
			if (term.length != 0) {
				if (window.ga && ga.create) {
					ga('send', 'pageview', '/search?q=' + term);
				}
			}
		}
		var delaySearchTrack = function() {
			searchTimer && clearTimeout(searchTimer);
			searchTimer = setTimeout(searchTrack, 500);
		}
		$('#search-tindie').keyup(delaySearchTrack);
		// mobile search bar tracking
		var mobileSearchTimer;
		var mobileSearchTrack = function() {
			var term = $('#search-tindie-mobile').val();
			if (term.length != 0) {
				if (window.ga && ga.create) {
					ga('send', 'pageview', '/search?q=' + term);
				}
			}
		}
		var delayMobileSearchTrack = function() {
			mobileSearchTimer && clearTimeout(mobileSearchTimer);
			mobileSearchTimer = setTimeout(mobileSearchTrack, 500);
		}
		$('#search-tindie-mobile').keyup(delayMobileSearchTrack);
	}
	return t;
})(tindie.search || {}, jQuery);