// GENERATED, PLEASE DO NOT MANUALLY EDIT
tindie.countries = [{
	"phone": "+61",
	"flag": "flags/au.gif",
	"code": "AU",
	"name": "Australia"
}, {
	"phone": "+1",
	"flag": "flags/ca.gif",
	"code": "CA",
	"name": "Canada"
}, {
	"phone": "+86",
	"flag": "flags/cn.gif",
	"code": "CN",
	"name": "China"
}, {
	"phone": "+49",
	"flag": "flags/de.gif",
	"code": "DE",
	"name": "Germany"
}, {
	"phone": "+31",
	"flag": "flags/nl.gif",
	"code": "NL",
	"name": "Netherlands"
}, {
	"phone": "+44",
	"flag": "flags/gb.gif",
	"code": "GB",
	"name": "United Kingdom"
}, {
	"phone": "+1",
	"flag": "flags/us.gif",
	"code": "US",
	"name": "United States of America"
}, {
	"phone": "+93",
	"flag": "flags/af.gif",
	"code": "AF",
	"name": "Afghanistan"
}, {
	"phone": "+358",
	"flag": "flags/ax.gif",
	"code": "AX",
	"name": "Åland Islands"
}, {
	"phone": "+355",
	"flag": "flags/al.gif",
	"code": "AL",
	"name": "Albania"
}, {
	"phone": "+213",
	"flag": "flags/dz.gif",
	"code": "DZ",
	"name": "Algeria"
}, {
	"phone": "+1",
	"flag": "flags/as.gif",
	"code": "AS",
	"name": "American Samoa"
}, {
	"phone": "+376",
	"flag": "flags/ad.gif",
	"code": "AD",
	"name": "Andorra"
}, {
	"phone": "+244",
	"flag": "flags/ao.gif",
	"code": "AO",
	"name": "Angola"
}, {
	"phone": "+1",
	"flag": "flags/ai.gif",
	"code": "AI",
	"name": "Anguilla"
}, {
	"phone": "+672",
	"flag": "flags/aq.gif",
	"code": "AQ",
	"name": "Antarctica"
}, {
	"phone": "+1",
	"flag": "flags/ag.gif",
	"code": "AG",
	"name": "Antigua and Barbuda"
}, {
	"phone": "+54",
	"flag": "flags/ar.gif",
	"code": "AR",
	"name": "Argentina"
}, {
	"phone": "+374",
	"flag": "flags/am.gif",
	"code": "AM",
	"name": "Armenia"
}, {
	"phone": "+297",
	"flag": "flags/aw.gif",
	"code": "AW",
	"name": "Aruba"
}, {
	"phone": "+43",
	"flag": "flags/at.gif",
	"code": "AT",
	"name": "Austria"
}, {
	"phone": "+994",
	"flag": "flags/az.gif",
	"code": "AZ",
	"name": "Azerbaijan"
}, {
	"phone": "+1",
	"flag": "flags/bs.gif",
	"code": "BS",
	"name": "Bahamas"
}, {
	"phone": "+973",
	"flag": "flags/bh.gif",
	"code": "BH",
	"name": "Bahrain"
}, {
	"phone": "+880",
	"flag": "flags/bd.gif",
	"code": "BD",
	"name": "Bangladesh"
}, {
	"phone": "+1",
	"flag": "flags/bb.gif",
	"code": "BB",
	"name": "Barbados"
}, {
	"phone": "+375",
	"flag": "flags/by.gif",
	"code": "BY",
	"name": "Belarus"
}, {
	"phone": "+32",
	"flag": "flags/be.gif",
	"code": "BE",
	"name": "Belgium"
}, {
	"phone": "+501",
	"flag": "flags/bz.gif",
	"code": "BZ",
	"name": "Belize"
}, {
	"phone": "+229",
	"flag": "flags/bj.gif",
	"code": "BJ",
	"name": "Benin"
}, {
	"phone": "+1",
	"flag": "flags/bm.gif",
	"code": "BM",
	"name": "Bermuda"
}, {
	"phone": "+975",
	"flag": "flags/bt.gif",
	"code": "BT",
	"name": "Bhutan"
}, {
	"phone": "+591",
	"flag": "flags/bo.gif",
	"code": "BO",
	"name": "Bolivia"
}, {
	"phone": "+599",
	"flag": "flags/bq.gif",
	"code": "BQ",
	"name": "Bonaire, Sint Eustatius and Saba"
}, {
	"phone": "+387",
	"flag": "flags/ba.gif",
	"code": "BA",
	"name": "Bosnia and Herzegovina"
}, {
	"phone": "+267",
	"flag": "flags/bw.gif",
	"code": "BW",
	"name": "Botswana"
}, {
	"phone": "+0",
	"flag": "flags/bv.gif",
	"code": "BV",
	"name": "Bouvet Island"
}, {
	"phone": "+55",
	"flag": "flags/br.gif",
	"code": "BR",
	"name": "Brazil"
}, {
	"phone": "+246",
	"flag": "flags/io.gif",
	"code": "IO",
	"name": "British Indian Ocean Territory"
}, {
	"phone": "+673",
	"flag": "flags/bn.gif",
	"code": "BN",
	"name": "Brunei"
}, {
	"phone": "+359",
	"flag": "flags/bg.gif",
	"code": "BG",
	"name": "Bulgaria"
}, {
	"phone": "+226",
	"flag": "flags/bf.gif",
	"code": "BF",
	"name": "Burkina Faso"
}, {
	"phone": "+257",
	"flag": "flags/bi.gif",
	"code": "BI",
	"name": "Burundi"
}, {
	"phone": "+238",
	"flag": "flags/cv.gif",
	"code": "CV",
	"name": "Cabo Verde"
}, {
	"phone": "+855",
	"flag": "flags/kh.gif",
	"code": "KH",
	"name": "Cambodia"
}, {
	"phone": "+237",
	"flag": "flags/cm.gif",
	"code": "CM",
	"name": "Cameroon"
}, {
	"phone": "+1",
	"flag": "flags/ky.gif",
	"code": "KY",
	"name": "Cayman Islands"
}, {
	"phone": "+236",
	"flag": "flags/cf.gif",
	"code": "CF",
	"name": "Central African Republic"
}, {
	"phone": "+235",
	"flag": "flags/td.gif",
	"code": "TD",
	"name": "Chad"
}, {
	"phone": "+56",
	"flag": "flags/cl.gif",
	"code": "CL",
	"name": "Chile"
}, {
	"phone": "+61",
	"flag": "flags/cx.gif",
	"code": "CX",
	"name": "Christmas Island"
}, {
	"phone": "+61",
	"flag": "flags/cc.gif",
	"code": "CC",
	"name": "Cocos (Keeling) Islands"
}, {
	"phone": "+57",
	"flag": "flags/co.gif",
	"code": "CO",
	"name": "Colombia"
}, {
	"phone": "+269",
	"flag": "flags/km.gif",
	"code": "KM",
	"name": "Comoros"
}, {
	"phone": "+242",
	"flag": "flags/cg.gif",
	"code": "CG",
	"name": "Congo"
}, {
	"phone": "+243",
	"flag": "flags/cd.gif",
	"code": "CD",
	"name": "Congo (the Democratic Republic of the)"
}, {
	"phone": "+682",
	"flag": "flags/ck.gif",
	"code": "CK",
	"name": "Cook Islands"
}, {
	"phone": "+506",
	"flag": "flags/cr.gif",
	"code": "CR",
	"name": "Costa Rica"
}, {
	"phone": "+225",
	"flag": "flags/ci.gif",
	"code": "CI",
	"name": "Côte d'Ivoire"
}, {
	"phone": "+385",
	"flag": "flags/hr.gif",
	"code": "HR",
	"name": "Croatia"
}, {
	"phone": "+53",
	"flag": "flags/cu.gif",
	"code": "CU",
	"name": "Cuba"
}, {
	"phone": "+599",
	"flag": "flags/cw.gif",
	"code": "CW",
	"name": "Curaçao"
}, {
	"phone": "+357",
	"flag": "flags/cy.gif",
	"code": "CY",
	"name": "Cyprus"
}, {
	"phone": "+420",
	"flag": "flags/cz.gif",
	"code": "CZ",
	"name": "Czechia"
}, {
	"phone": "+45",
	"flag": "flags/dk.gif",
	"code": "DK",
	"name": "Denmark"
}, {
	"phone": "+253",
	"flag": "flags/dj.gif",
	"code": "DJ",
	"name": "Djibouti"
}, {
	"phone": "+1",
	"flag": "flags/dm.gif",
	"code": "DM",
	"name": "Dominica"
}, {
	"phone": "+1",
	"flag": "flags/do.gif",
	"code": "DO",
	"name": "Dominican Republic"
}, {
	"phone": "+593",
	"flag": "flags/ec.gif",
	"code": "EC",
	"name": "Ecuador"
}, {
	"phone": "+20",
	"flag": "flags/eg.gif",
	"code": "EG",
	"name": "Egypt"
}, {
	"phone": "+503",
	"flag": "flags/sv.gif",
	"code": "SV",
	"name": "El Salvador"
}, {
	"phone": "+240",
	"flag": "flags/gq.gif",
	"code": "GQ",
	"name": "Equatorial Guinea"
}, {
	"phone": "+291",
	"flag": "flags/er.gif",
	"code": "ER",
	"name": "Eritrea"
}, {
	"phone": "+372",
	"flag": "flags/ee.gif",
	"code": "EE",
	"name": "Estonia"
}, {
	"phone": "+268",
	"flag": "flags/sz.gif",
	"code": "SZ",
	"name": "Eswatini"
}, {
	"phone": "+251",
	"flag": "flags/et.gif",
	"code": "ET",
	"name": "Ethiopia"
}, {
	"phone": "+500",
	"flag": "flags/fk.gif",
	"code": "FK",
	"name": "Falkland Islands  [Malvinas]"
}, {
	"phone": "+298",
	"flag": "flags/fo.gif",
	"code": "FO",
	"name": "Faroe Islands"
}, {
	"phone": "+679",
	"flag": "flags/fj.gif",
	"code": "FJ",
	"name": "Fiji"
}, {
	"phone": "+358",
	"flag": "flags/fi.gif",
	"code": "FI",
	"name": "Finland"
}, {
	"phone": "+33",
	"flag": "flags/fr.gif",
	"code": "FR",
	"name": "France"
}, {
	"phone": "+594",
	"flag": "flags/gf.gif",
	"code": "GF",
	"name": "French Guiana"
}, {
	"phone": "+689",
	"flag": "flags/pf.gif",
	"code": "PF",
	"name": "French Polynesia"
}, {
	"phone": "+0",
	"flag": "flags/tf.gif",
	"code": "TF",
	"name": "French Southern Territories"
}, {
	"phone": "+241",
	"flag": "flags/ga.gif",
	"code": "GA",
	"name": "Gabon"
}, {
	"phone": "+220",
	"flag": "flags/gm.gif",
	"code": "GM",
	"name": "Gambia"
}, {
	"phone": "+995",
	"flag": "flags/ge.gif",
	"code": "GE",
	"name": "Georgia"
}, {
	"phone": "+233",
	"flag": "flags/gh.gif",
	"code": "GH",
	"name": "Ghana"
}, {
	"phone": "+350",
	"flag": "flags/gi.gif",
	"code": "GI",
	"name": "Gibraltar"
}, {
	"phone": "+30",
	"flag": "flags/gr.gif",
	"code": "GR",
	"name": "Greece"
}, {
	"phone": "+299",
	"flag": "flags/gl.gif",
	"code": "GL",
	"name": "Greenland"
}, {
	"phone": "+1",
	"flag": "flags/gd.gif",
	"code": "GD",
	"name": "Grenada"
}, {
	"phone": "+590",
	"flag": "flags/gp.gif",
	"code": "GP",
	"name": "Guadeloupe"
}, {
	"phone": "+1",
	"flag": "flags/gu.gif",
	"code": "GU",
	"name": "Guam"
}, {
	"phone": "+502",
	"flag": "flags/gt.gif",
	"code": "GT",
	"name": "Guatemala"
}, {
	"phone": "+44",
	"flag": "flags/gg.gif",
	"code": "GG",
	"name": "Guernsey"
}, {
	"phone": "+224",
	"flag": "flags/gn.gif",
	"code": "GN",
	"name": "Guinea"
}, {
	"phone": "+245",
	"flag": "flags/gw.gif",
	"code": "GW",
	"name": "Guinea-Bissau"
}, {
	"phone": "+592",
	"flag": "flags/gy.gif",
	"code": "GY",
	"name": "Guyana"
}, {
	"phone": "+509",
	"flag": "flags/ht.gif",
	"code": "HT",
	"name": "Haiti"
}, {
	"phone": "+0",
	"flag": "flags/hm.gif",
	"code": "HM",
	"name": "Heard Island and McDonald Islands"
}, {
	"phone": "+379",
	"flag": "flags/va.gif",
	"code": "VA",
	"name": "Holy See"
}, {
	"phone": "+504",
	"flag": "flags/hn.gif",
	"code": "HN",
	"name": "Honduras"
}, {
	"phone": "+852",
	"flag": "flags/hk.gif",
	"code": "HK",
	"name": "Hong Kong"
}, {
	"phone": "+36",
	"flag": "flags/hu.gif",
	"code": "HU",
	"name": "Hungary"
}, {
	"phone": "+354",
	"flag": "flags/is.gif",
	"code": "IS",
	"name": "Iceland"
}, {
	"phone": "+91",
	"flag": "flags/in.gif",
	"code": "IN",
	"name": "India"
}, {
	"phone": "+62",
	"flag": "flags/id.gif",
	"code": "ID",
	"name": "Indonesia"
}, {
	"phone": "+98",
	"flag": "flags/ir.gif",
	"code": "IR",
	"name": "Iran"
}, {
	"phone": "+964",
	"flag": "flags/iq.gif",
	"code": "IQ",
	"name": "Iraq"
}, {
	"phone": "+353",
	"flag": "flags/ie.gif",
	"code": "IE",
	"name": "Ireland"
}, {
	"phone": "+44",
	"flag": "flags/im.gif",
	"code": "IM",
	"name": "Isle of Man"
}, {
	"phone": "+972",
	"flag": "flags/il.gif",
	"code": "IL",
	"name": "Israel"
}, {
	"phone": "+39",
	"flag": "flags/it.gif",
	"code": "IT",
	"name": "Italy"
}, {
	"phone": "+1",
	"flag": "flags/jm.gif",
	"code": "JM",
	"name": "Jamaica"
}, {
	"phone": "+81",
	"flag": "flags/jp.gif",
	"code": "JP",
	"name": "Japan"
}, {
	"phone": "+44",
	"flag": "flags/je.gif",
	"code": "JE",
	"name": "Jersey"
}, {
	"phone": "+962",
	"flag": "flags/jo.gif",
	"code": "JO",
	"name": "Jordan"
}, {
	"phone": "+7",
	"flag": "flags/kz.gif",
	"code": "KZ",
	"name": "Kazakhstan"
}, {
	"phone": "+254",
	"flag": "flags/ke.gif",
	"code": "KE",
	"name": "Kenya"
}, {
	"phone": "+686",
	"flag": "flags/ki.gif",
	"code": "KI",
	"name": "Kiribati"
}, {
	"phone": "+965",
	"flag": "flags/kw.gif",
	"code": "KW",
	"name": "Kuwait"
}, {
	"phone": "+996",
	"flag": "flags/kg.gif",
	"code": "KG",
	"name": "Kyrgyzstan"
}, {
	"phone": "+856",
	"flag": "flags/la.gif",
	"code": "LA",
	"name": "Laos"
}, {
	"phone": "+371",
	"flag": "flags/lv.gif",
	"code": "LV",
	"name": "Latvia"
}, {
	"phone": "+961",
	"flag": "flags/lb.gif",
	"code": "LB",
	"name": "Lebanon"
}, {
	"phone": "+266",
	"flag": "flags/ls.gif",
	"code": "LS",
	"name": "Lesotho"
}, {
	"phone": "+231",
	"flag": "flags/lr.gif",
	"code": "LR",
	"name": "Liberia"
}, {
	"phone": "+218",
	"flag": "flags/ly.gif",
	"code": "LY",
	"name": "Libya"
}, {
	"phone": "+423",
	"flag": "flags/li.gif",
	"code": "LI",
	"name": "Liechtenstein"
}, {
	"phone": "+370",
	"flag": "flags/lt.gif",
	"code": "LT",
	"name": "Lithuania"
}, {
	"phone": "+352",
	"flag": "flags/lu.gif",
	"code": "LU",
	"name": "Luxembourg"
}, {
	"phone": "+853",
	"flag": "flags/mo.gif",
	"code": "MO",
	"name": "Macao"
}, {
	"phone": "+261",
	"flag": "flags/mg.gif",
	"code": "MG",
	"name": "Madagascar"
}, {
	"phone": "+265",
	"flag": "flags/mw.gif",
	"code": "MW",
	"name": "Malawi"
}, {
	"phone": "+60",
	"flag": "flags/my.gif",
	"code": "MY",
	"name": "Malaysia"
}, {
	"phone": "+960",
	"flag": "flags/mv.gif",
	"code": "MV",
	"name": "Maldives"
}, {
	"phone": "+223",
	"flag": "flags/ml.gif",
	"code": "ML",
	"name": "Mali"
}, {
	"phone": "+356",
	"flag": "flags/mt.gif",
	"code": "MT",
	"name": "Malta"
}, {
	"phone": "+692",
	"flag": "flags/mh.gif",
	"code": "MH",
	"name": "Marshall Islands"
}, {
	"phone": "+596",
	"flag": "flags/mq.gif",
	"code": "MQ",
	"name": "Martinique"
}, {
	"phone": "+222",
	"flag": "flags/mr.gif",
	"code": "MR",
	"name": "Mauritania"
}, {
	"phone": "+230",
	"flag": "flags/mu.gif",
	"code": "MU",
	"name": "Mauritius"
}, {
	"phone": "+262",
	"flag": "flags/yt.gif",
	"code": "YT",
	"name": "Mayotte"
}, {
	"phone": "+52",
	"flag": "flags/mx.gif",
	"code": "MX",
	"name": "Mexico"
}, {
	"phone": "+691",
	"flag": "flags/fm.gif",
	"code": "FM",
	"name": "Micronesia (Federated States of)"
}, {
	"phone": "+373",
	"flag": "flags/md.gif",
	"code": "MD",
	"name": "Moldova"
}, {
	"phone": "+377",
	"flag": "flags/mc.gif",
	"code": "MC",
	"name": "Monaco"
}, {
	"phone": "+976",
	"flag": "flags/mn.gif",
	"code": "MN",
	"name": "Mongolia"
}, {
	"phone": "+382",
	"flag": "flags/me.gif",
	"code": "ME",
	"name": "Montenegro"
}, {
	"phone": "+1",
	"flag": "flags/ms.gif",
	"code": "MS",
	"name": "Montserrat"
}, {
	"phone": "+212",
	"flag": "flags/ma.gif",
	"code": "MA",
	"name": "Morocco"
}, {
	"phone": "+258",
	"flag": "flags/mz.gif",
	"code": "MZ",
	"name": "Mozambique"
}, {
	"phone": "+95",
	"flag": "flags/mm.gif",
	"code": "MM",
	"name": "Myanmar"
}, {
	"phone": "+264",
	"flag": "flags/na.gif",
	"code": "NA",
	"name": "Namibia"
}, {
	"phone": "+674",
	"flag": "flags/nr.gif",
	"code": "NR",
	"name": "Nauru"
}, {
	"phone": "+977",
	"flag": "flags/np.gif",
	"code": "NP",
	"name": "Nepal"
}, {
	"phone": "+687",
	"flag": "flags/nc.gif",
	"code": "NC",
	"name": "New Caledonia"
}, {
	"phone": "+64",
	"flag": "flags/nz.gif",
	"code": "NZ",
	"name": "New Zealand"
}, {
	"phone": "+505",
	"flag": "flags/ni.gif",
	"code": "NI",
	"name": "Nicaragua"
}, {
	"phone": "+227",
	"flag": "flags/ne.gif",
	"code": "NE",
	"name": "Niger"
}, {
	"phone": "+234",
	"flag": "flags/ng.gif",
	"code": "NG",
	"name": "Nigeria"
}, {
	"phone": "+683",
	"flag": "flags/nu.gif",
	"code": "NU",
	"name": "Niue"
}, {
	"phone": "+672",
	"flag": "flags/nf.gif",
	"code": "NF",
	"name": "Norfolk Island"
}, {
	"phone": "+850",
	"flag": "flags/kp.gif",
	"code": "KP",
	"name": "North Korea"
}, {
	"phone": "+389",
	"flag": "flags/mk.gif",
	"code": "MK",
	"name": "North Macedonia"
}, {
	"phone": "+1",
	"flag": "flags/mp.gif",
	"code": "MP",
	"name": "Northern Mariana Islands"
}, {
	"phone": "+47",
	"flag": "flags/no.gif",
	"code": "NO",
	"name": "Norway"
}, {
	"phone": "+968",
	"flag": "flags/om.gif",
	"code": "OM",
	"name": "Oman"
}, {
	"phone": "+92",
	"flag": "flags/pk.gif",
	"code": "PK",
	"name": "Pakistan"
}, {
	"phone": "+680",
	"flag": "flags/pw.gif",
	"code": "PW",
	"name": "Palau"
}, {
	"phone": "+970",
	"flag": "flags/ps.gif",
	"code": "PS",
	"name": "Palestine, State of"
}, {
	"phone": "+507",
	"flag": "flags/pa.gif",
	"code": "PA",
	"name": "Panama"
}, {
	"phone": "+675",
	"flag": "flags/pg.gif",
	"code": "PG",
	"name": "Papua New Guinea"
}, {
	"phone": "+595",
	"flag": "flags/py.gif",
	"code": "PY",
	"name": "Paraguay"
}, {
	"phone": "+51",
	"flag": "flags/pe.gif",
	"code": "PE",
	"name": "Peru"
}, {
	"phone": "+63",
	"flag": "flags/ph.gif",
	"code": "PH",
	"name": "Philippines"
}, {
	"phone": "+64",
	"flag": "flags/pn.gif",
	"code": "PN",
	"name": "Pitcairn"
}, {
	"phone": "+48",
	"flag": "flags/pl.gif",
	"code": "PL",
	"name": "Poland"
}, {
	"phone": "+351",
	"flag": "flags/pt.gif",
	"code": "PT",
	"name": "Portugal"
}, {
	"phone": "+1",
	"flag": "flags/pr.gif",
	"code": "PR",
	"name": "Puerto Rico"
}, {
	"phone": "+974",
	"flag": "flags/qa.gif",
	"code": "QA",
	"name": "Qatar"
}, {
	"phone": "+262",
	"flag": "flags/re.gif",
	"code": "RE",
	"name": "Réunion"
}, {
	"phone": "+40",
	"flag": "flags/ro.gif",
	"code": "RO",
	"name": "Romania"
}, {
	"phone": "+7",
	"flag": "flags/ru.gif",
	"code": "RU",
	"name": "Russia"
}, {
	"phone": "+250",
	"flag": "flags/rw.gif",
	"code": "RW",
	"name": "Rwanda"
}, {
	"phone": "+590",
	"flag": "flags/bl.gif",
	"code": "BL",
	"name": "Saint Barthélemy"
}, {
	"phone": "+290",
	"flag": "flags/sh.gif",
	"code": "SH",
	"name": "Saint Helena, Ascension and Tristan da Cunha"
}, {
	"phone": "+1",
	"flag": "flags/kn.gif",
	"code": "KN",
	"name": "Saint Kitts and Nevis"
}, {
	"phone": "+1",
	"flag": "flags/lc.gif",
	"code": "LC",
	"name": "Saint Lucia"
}, {
	"phone": "+590",
	"flag": "flags/mf.gif",
	"code": "MF",
	"name": "Saint Martin (French part)"
}, {
	"phone": "+508",
	"flag": "flags/pm.gif",
	"code": "PM",
	"name": "Saint Pierre and Miquelon"
}, {
	"phone": "+1",
	"flag": "flags/vc.gif",
	"code": "VC",
	"name": "Saint Vincent and the Grenadines"
}, {
	"phone": "+685",
	"flag": "flags/ws.gif",
	"code": "WS",
	"name": "Samoa"
}, {
	"phone": "+378",
	"flag": "flags/sm.gif",
	"code": "SM",
	"name": "San Marino"
}, {
	"phone": "+239",
	"flag": "flags/st.gif",
	"code": "ST",
	"name": "Sao Tome and Principe"
}, {
	"phone": "+966",
	"flag": "flags/sa.gif",
	"code": "SA",
	"name": "Saudi Arabia"
}, {
	"phone": "+221",
	"flag": "flags/sn.gif",
	"code": "SN",
	"name": "Senegal"
}, {
	"phone": "+381",
	"flag": "flags/rs.gif",
	"code": "RS",
	"name": "Serbia"
}, {
	"phone": "+248",
	"flag": "flags/sc.gif",
	"code": "SC",
	"name": "Seychelles"
}, {
	"phone": "+232",
	"flag": "flags/sl.gif",
	"code": "SL",
	"name": "Sierra Leone"
}, {
	"phone": "+65",
	"flag": "flags/sg.gif",
	"code": "SG",
	"name": "Singapore"
}, {
	"phone": "+1",
	"flag": "flags/sx.gif",
	"code": "SX",
	"name": "Sint Maarten (Dutch part)"
}, {
	"phone": "+421",
	"flag": "flags/sk.gif",
	"code": "SK",
	"name": "Slovakia"
}, {
	"phone": "+386",
	"flag": "flags/si.gif",
	"code": "SI",
	"name": "Slovenia"
}, {
	"phone": "+677",
	"flag": "flags/sb.gif",
	"code": "SB",
	"name": "Solomon Islands"
}, {
	"phone": "+252",
	"flag": "flags/so.gif",
	"code": "SO",
	"name": "Somalia"
}, {
	"phone": "+27",
	"flag": "flags/za.gif",
	"code": "ZA",
	"name": "South Africa"
}, {
	"phone": "+500",
	"flag": "flags/gs.gif",
	"code": "GS",
	"name": "South Georgia and the South Sandwich Islands"
}, {
	"phone": "+82",
	"flag": "flags/kr.gif",
	"code": "KR",
	"name": "South Korea"
}, {
	"phone": "+211",
	"flag": "flags/ss.gif",
	"code": "SS",
	"name": "South Sudan"
}, {
	"phone": "+34",
	"flag": "flags/es.gif",
	"code": "ES",
	"name": "Spain"
}, {
	"phone": "+94",
	"flag": "flags/lk.gif",
	"code": "LK",
	"name": "Sri Lanka"
}, {
	"phone": "+249",
	"flag": "flags/sd.gif",
	"code": "SD",
	"name": "Sudan"
}, {
	"phone": "+597",
	"flag": "flags/sr.gif",
	"code": "SR",
	"name": "Suriname"
}, {
	"phone": "+47",
	"flag": "flags/sj.gif",
	"code": "SJ",
	"name": "Svalbard and Jan Mayen"
}, {
	"phone": "+46",
	"flag": "flags/se.gif",
	"code": "SE",
	"name": "Sweden"
}, {
	"phone": "+41",
	"flag": "flags/ch.gif",
	"code": "CH",
	"name": "Switzerland"
}, {
	"phone": "+963",
	"flag": "flags/sy.gif",
	"code": "SY",
	"name": "Syria"
}, {
	"phone": "+886",
	"flag": "flags/tw.gif",
	"code": "TW",
	"name": "Taiwan"
}, {
	"phone": "+992",
	"flag": "flags/tj.gif",
	"code": "TJ",
	"name": "Tajikistan"
}, {
	"phone": "+255",
	"flag": "flags/tz.gif",
	"code": "TZ",
	"name": "Tanzania"
}, {
	"phone": "+66",
	"flag": "flags/th.gif",
	"code": "TH",
	"name": "Thailand"
}, {
	"phone": "+670",
	"flag": "flags/tl.gif",
	"code": "TL",
	"name": "Timor-Leste"
}, {
	"phone": "+228",
	"flag": "flags/tg.gif",
	"code": "TG",
	"name": "Togo"
}, {
	"phone": "+690",
	"flag": "flags/tk.gif",
	"code": "TK",
	"name": "Tokelau"
}, {
	"phone": "+676",
	"flag": "flags/to.gif",
	"code": "TO",
	"name": "Tonga"
}, {
	"phone": "+1",
	"flag": "flags/tt.gif",
	"code": "TT",
	"name": "Trinidad and Tobago"
}, {
	"phone": "+216",
	"flag": "flags/tn.gif",
	"code": "TN",
	"name": "Tunisia"
}, {
	"phone": "+90",
	"flag": "flags/tr.gif",
	"code": "TR",
	"name": "Turkey"
}, {
	"phone": "+993",
	"flag": "flags/tm.gif",
	"code": "TM",
	"name": "Turkmenistan"
}, {
	"phone": "+1",
	"flag": "flags/tc.gif",
	"code": "TC",
	"name": "Turks and Caicos Islands"
}, {
	"phone": "+688",
	"flag": "flags/tv.gif",
	"code": "TV",
	"name": "Tuvalu"
}, {
	"phone": "+256",
	"flag": "flags/ug.gif",
	"code": "UG",
	"name": "Uganda"
}, {
	"phone": "+380",
	"flag": "flags/ua.gif",
	"code": "UA",
	"name": "Ukraine"
}, {
	"phone": "+971",
	"flag": "flags/ae.gif",
	"code": "AE",
	"name": "United Arab Emirates"
}, {
	"phone": "+1",
	"flag": "flags/um.gif",
	"code": "UM",
	"name": "United States Minor Outlying Islands"
}, {
	"phone": "+598",
	"flag": "flags/uy.gif",
	"code": "UY",
	"name": "Uruguay"
}, {
	"phone": "+998",
	"flag": "flags/uz.gif",
	"code": "UZ",
	"name": "Uzbekistan"
}, {
	"phone": "+678",
	"flag": "flags/vu.gif",
	"code": "VU",
	"name": "Vanuatu"
}, {
	"phone": "+58",
	"flag": "flags/ve.gif",
	"code": "VE",
	"name": "Venezuela"
}, {
	"phone": "+84",
	"flag": "flags/vn.gif",
	"code": "VN",
	"name": "Vietnam"
}, {
	"phone": "+1",
	"flag": "flags/vg.gif",
	"code": "VG",
	"name": "Virgin Islands (British)"
}, {
	"phone": "+1",
	"flag": "flags/vi.gif",
	"code": "VI",
	"name": "Virgin Islands (U.S.)"
}, {
	"phone": "+681",
	"flag": "flags/wf.gif",
	"code": "WF",
	"name": "Wallis and Futuna"
}, {
	"phone": "+212",
	"flag": "flags/eh.gif",
	"code": "EH",
	"name": "Western Sahara"
}, {
	"phone": "+967",
	"flag": "flags/ye.gif",
	"code": "YE",
	"name": "Yemen"
}, {
	"phone": "+260",
	"flag": "flags/zm.gif",
	"code": "ZM",
	"name": "Zambia"
}, {
	"phone": "+263",
	"flag": "flags/zw.gif",
	"code": "ZW",
	"name": "Zimbabwe"
}];