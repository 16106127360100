tindie.handlers = (function(t, $) {
	var countries = tindie.countries || [];
	ko.bindingHandlers = ko.bindingHandlers || {};
	ko.bindingHandlers.hidden = {
		update: function(element, valueAccessor) {
			ko.bindingHandlers.visible.update(element, function() {
				return !ko.utils.unwrapObservable(valueAccessor());
			});
		}
	};
	ko.bindingHandlers.checkedBool = {
		init: function(element, valueAccessor, allBindingsAccessor) {
			var observable = valueAccessor(),
				interceptor = ko.computed({
					read: function() {
						if (observable()) {
							return observable().toString();
						}
						return observable;
					},
					write: function(newValue) {
						observable(newValue === "true");
					},
					owner: this
				});
			ko.applyBindingsToNode(element, {
				checked: interceptor
			});
		}
	};
	ko.bindingHandlers.currencyText = {
		update: function(element, valueAccessor, allBindingsAccessor) {
			var value = ko.utils.unwrapObservable(valueAccessor()),
				formattedValue = OSREC.CurrencyFormatter.format(value, {
					currency: 'USD'
				});
			ko.bindingHandlers.text.update(element, function() {
				return formattedValue;
			});
		}
	};
	ko.bindingHandlers.bootstrapErrors = {
		update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
			ko.unwrap(valueAccessor());
			if (valueAccessor().silent || !valueAccessor().isModified()) {
				return;
			}
			var valueIsValid = valueAccessor().isValid();
			if (!valueIsValid) {
				$(element).parents('.form-group').first().addClass("has-error");
			} else {
				$(element).parents('.form-group').first().removeClass("has-error");
			}
		}
	};
	ko.bindingHandlers.showModal = {
		init: function(element, valueAccessor) {
			$(element).modal({
				backdrop: 'static',
				keyboard: false,
				show: false
			});
		},
		update: function(element, valueAccessor) {
			var value = valueAccessor();
			if (ko.utils.unwrapObservable(value)) {
				$(element).modal('show');
			} else {
				$(element).modal('hide');
			}
		}
	};
	ko.bindingHandlers.clickArray = {
		init: function(element, valueAccessor) {
			var handlers = valueAccessor();
			ko.applyBindingsToNode(element, {
				click: function() {
					for (var i = 0; i < handlers.length; i++) {
						handlers[i].apply(this, arguments);
					}
				}
			});
		}
	};
	ko.bindingHandlers.scrollToTop = {
		update: function(element, valueAccessor, allBindings) {
			var value = valueAccessor();
			var valueUnwrapped = ko.unwrap(value);
			if (valueUnwrapped == true) {
				var main_el = $('main[role="main"]').offset();
				$("html, body").animate({
					scrollTop: main_el.top
				}, 200);
			}
		}
	};
	ko.bindingHandlers.fadeDollars = {
		'init': function(element, valueAccessor, allBindings, viewModel, bindingContext) {
			$(element).data('last_value', ko.unwrap(valueAccessor()));
		},
		'update': function(element, valueAccessor, allBindings) {
			// do nothing if not already visible
			var visible = allBindings.get('visible');
			if (visible === "" || (typeof(visible) == "function" && !visible())) {
				return;
			}
			var el = $(element);
			var last_value = el.data('last_value');
			var value = ko.unwrap(valueAccessor());
			if (value !== last_value) {
				el.fadeOut('fast', function() {
					ko.bindingHandlers.currencyText.update(element, valueAccessor);
					el.fadeIn('fast');
					el.data('last_value', value);
				});
			}
		}
	};
	ko.bindingHandlers.fadeForex = {
		'update': function(element, valueAccessor, allBindings, viewModel) {
			var el = $(element),
				last_value = el.data('last_value'),
				value = ko.unwrap(valueAccessor());
			ko.bindingHandlers.text.update(element, valueAccessor);
			if (el.text()) {
				var text = OSREC.CurrencyFormatter.format(el.text(), {
					currency: viewModel.currencyInfo().fxCurrency()
				});
				el.text('(' + text + ' ' + viewModel.currencyInfo().fxCurrency() + ')');
			}
		}
	};
	ko.bindingHandlers.clipboard = {
		init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
			var options = ko.unwrap(valueAccessor());
			if (options.textComputed) {
				options.text = function(nodeToIgnore) {
					return options.textComputed();
				};
			}
			if (options.onmodal) {
				options.container = element;
			}
			var cboard = new ClipboardJS(element, options);
			if (options.success) {
				cboard.on('success', options.success);
			}
			if (options.error) {
				cboard.on('error', options.error);
			}
			ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
				cboard.destroy();
			});
		},
	}
	ko.bindingHandlers.fadeVisible = {
		init: function(element, valueAccessor) {
			// Initially set the element to be instantly visible/hidden depending on the value
			var value = valueAccessor();
			$(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
		},
		update: function(element, valueAccessor) {
			// Whenever the value subsequently changes, slowly fade the element in or out
			var value = valueAccessor();
			ko.unwrap(value) ? $(element).fadeIn() : $(element).fadeOut();
		}
	}
	ko.bindingHandlers.disableEnterKey = {
		// Disable enter key press on element's parent form until value is changed to true
		update: function(element, valueAccessor) {
			var form = $(element).parents("form:first");
			ko.utils.registerEventHandler(form, "keypress", function(event) {
				var keyCode = (event.which ? event.which : event.keyCode);
				return !(keyCode === 13 && ko.unwrap(valueAccessor()));
			});
		}
	}
	ko.bindingHandlers.preventDoubleSubmit = {
		// Prevents a form from being submitted multiple times
		// Use on input type="submit" instead of preventDoubleClick
		// valueAccessor is an optional param to change element value and disable it (can put same value as original for disable only)
		// ex usage: data_bind = "preventDoubleSubmit: 'Submitting...'"
		init: function(element, valueAccessor, allBindings) {
			var preventClick = false,
				recentlyClicked = false,
				value = ko.unwrap(valueAccessor()),
				speed = allBindings().preventDoubleSubmitSpeed || 5000;
			var changeValue = function() {
				if (value) {
					var originalValue = $(element).val();
					ko.bindingHandlers.enable.update(element, function() {
						return false;
					});
					ko.bindingHandlers.value.init(element, function() {
						return value;
					}, allBindings);
					setTimeout(function() {
						ko.bindingHandlers.value.init(element, function() {
							return originalValue;
						}, allBindings);
						ko.bindingHandlers.enable.update(element, function() {
							return true;
						});
					}, speed);
				}
			};
			var formElement = $(element).parents('form')[0];
			ko.applyBindingsToNode(formElement, {
				submit: function() {
					if (!preventClick) {
						changeValue();
						preventClick = true;
						setTimeout(function() {
							preventClick = false;
						}, speed); // re-enable in case user cancels redirect
						return true;
					}
					return false;
				}
			});
		}
	}
	ko.bindingHandlers.preventDoubleClick = {
		// Prevents a button from being clicked multiple times
		// valueAccessor is an optional param to change element value and disable it (can put same value as original for disable only)
		// ex usage: data_bind = "preventDoubleClick: 'Submitting...'"
		init: function(element, valueAccessor, allBindings) {
			var preventClick = false,
				recentlyClicked = false,
				value = ko.unwrap(valueAccessor()),
				speed = allBindings().preventDoubleClickSpeed || 5000;
			var changeValue = function() {
				if (value) {
					var originalValue = $(element).text();
					ko.bindingHandlers.enable.update(element, function() {
						return false;
					});
					ko.bindingHandlers.text.update(element, function() {
						return value;
					});
					setTimeout(function() {
						ko.bindingHandlers.text.update(element, function() {
							return originalValue;
						});
						ko.bindingHandlers.enable.update(element, function() {
							return true;
						});
					}, speed);
				}
			};
			ko.applyBindingsToNode(element, {
				click: function() {
					if (!preventClick) {
						changeValue();
						preventClick = true;
						setTimeout(function() {
							preventClick = false;
						}, speed); // re-enable in case user cancels redirect
						return true;
					}
					return false;
				}
			});
		}
	}
	ko.bindingHandlers.daterangepicker = {
		// Binds a daterange picker to element
		// Options can be passed by passing daterangepickerOptions as an additional attribute binding
		init: function(element, valueAccessor, allBindingsAccessor) {
			var options = allBindingsAccessor().daterangepickerOptions || {};
			if (options.startDate) {
				options.startDate = moment(options.startDate).format('MM/DD/YYYY');
				if (options.endDate) {
					options.endDate = moment(options.endDate).format('MM/DD/YYYY');
				} else {
					options.endDate = moment().format('MM/DD/YYYY');
				}
			} else {
				options = {
					autoUpdateInput: false
				};
			}
			$(element).daterangepicker(options);
			ko.utils.registerEventHandler(element, "change", function() {
				var observable = valueAccessor();
				var startDate = moment(new Date($(element).val().split("-")[0])).format('YYYY-MM-DD')
				var endDate = moment(new Date($(element).val().split("-")[1])).format('YYYY-MM-DD')
				observable([startDate, endDate]);
			});
			ko.utils.registerEventHandler(element, "apply.daterangepicker", function(ev, picker) {
				$(element).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
				var observable = valueAccessor();
				observable([picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD')]);
			});
		},
		update: function(element, valueAccessor) {
			var value = ko.utils.unwrapObservable(valueAccessor());
			if (value) {
				var startDate = moment(value[0]).format('MM/DD/YYYY');
				var endDate = moment(value[1]).format('MM/DD/YYYY');
				$(element).data('daterangepicker').setStartDate(startDate);
				$(element).data('daterangepicker').setEndDate(endDate);
				$(element).val(startDate + ' - ' + endDate);
			}
		}
	}
	// assign to a dropdown of either countries or country + phone.
	// will split by ( to strip off the phone.
	ko.bindingHandlers.countryFlag = {
		update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
			ko.unwrap(valueAccessor());
			var observable = valueAccessor();
			if (typeof(observable()) !== "undefined") {
				var select = $(element);
				var countryName = select.find("option:selected").text().split(" (")[0];
				var url = 'none';
				if (countryName[0] != "+") {
					var country = null;
					for (var i = 0; i < countries.length; i++) {
						var item = countries[i];
						if (countryName == item.name) {
							country = item;
							break;
						}
					};
					if (country != null) {
						url = "url('" + staticPath + country.flag + "')";
					}
				}
				select.css("background-image", url);
			}
		},
		override: function(element, country) {
			if (country != null) {
				var select = $(element);
				url = "url('" + staticPath + country.flag + "')";
				select.css("background-image", url);
			}
		}
	};
	ko.bindingHandlers.errorTooltip = {
		update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
			ko.unwrap(valueAccessor());
			var observable = valueAccessor();
			if (observable().length > 0) {
				var errText = "Please double-check your info before submitting: " + observable()[0];
				var parentWrap = $(element);
				if (typeof parentWrap.data("bs.tooltip") !== "undefined") {
					parentWrap.data("bs.tooltip").options.title = errText;
				} else {
					parentWrap.tooltip({
						title: errText,
						placement: "right",
						template: '<div class="tooltip error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
					});
				}
			} else {
				var parentWrap = $(element);
				if (typeof parentWrap.data("bs.tooltip") !== "undefined") {
					parentWrap.data("bs.tooltip").options.title = "";
					parentWrap.tooltip("hide");
				}
			}
		}
	}
	return t;
})(tindie.handlers || {}, jQuery);