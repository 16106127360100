tindie.auth = (function(t, $) {
	var aj = tindie.ajax,
		check_email_url = "/accounts/check_email/",
		check_username_url = "/accounts/check_username/",
		register_user_url = $("#complete-registration-form").attr("action") + "j/";
	checkEmail = function(view) {
		var url = check_email_url;
		var data = {
			email: view.completeEmail()
		};
		aj.ajax_update_viewmodel(url, data, view, function(errormsg) {
			view.completeEmail.setError(errormsg[0]);
			view.completeEmail.isModified(true);
			if (errormsg[0] == "Email already registered!") {
				view.emailLoginHint(true);
			}
		}, function(result) {});
	};
	t.checkUsername = function(view) {
		var url = check_username_url;
		var data = {
			username: view.completeUsername()
		};
		aj.ajax_update_viewmodel(url, data, view, function(errormsg) {
			view.completeUsername.setError(errormsg[0]);
			view.completeUsername.isModified(true);
			if (errormsg[0] == "Username taken!") {
				view.loginHint(true);
			}
		}, function(result) {
			view.usernameMessage(result.message);
		});
	};
	t.registerUser = function(view, data, errors, callback) {
		var url = register_user_url;
		aj.ajax_update_viewmodel(url, data, view, errors, callback);
	};
	t.LoginBox = function() {
		var self = this;
		self.completeEmail = ko.observable().extend({
			required: {
				message: "Please enter your username or email"
			}
		});
		self.completePassword = ko.observable().extend({
			required: {
				message: "Please enter your password"
			}
		});
	};
	t.RegisterBox = function() {
		var self = this;
		self.completeEmail = ko.observable().extend({
			required: {
				message: "Please enter your email"
			},
			email: true
		});
		self.completeEmail2 = ko.observable().extend({
			required: {
				message: "Please confirm your email"
			},
			areSame: {
				params: self.completeEmail,
				message: "Emails do not match!"
			}
		});
		self.completeUsername = ko.observable().extend({
			required: {
				message: "Please enter a username"
			}
		});
		self.completePassword = ko.observable().extend({
			required: {
				message: "Please enter a password"
			}
		});
		self.usernameMessage = ko.observable("");
		self.emailLoginHint = ko.observable(false);
		self.loginHint = ko.observable(false);
		self.completeEmail.subscribe(function() {
			if (self.completeEmail() != "") {
				self.emailLoginHint(false);
				checkEmail(self);
			}
		});
		self.completeUsername.subscribe(function() {
			if (self.completeUsername() != "") {
				self.usernameMessage("");
				self.loginHint(false);
				t.checkUsername(self);
			}
		});
		self.submitRegistration = function() {
			if (window.fbq) {
				//fbq('track', 'CompleteRegistration');
			}
			return true;
		};
	};
	t.init = function() {};
	return t;
})(tindie.auth || {}, jQuery);