/* global tindie, ko, libphonenumber, newrelic */

tindie.addresses = (function(t, $) {
    ko.validation = ko.validation || {};
    ko.validation.rules = ko.validation.rules || {};
    t.getRegionURL = '/georegion/';
    t.euCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'];
    t.nonEuCountries = ['AD', 'AL', 'BA', 'BY', 'CH', 'GB', 'GI', 'IS', 'LI', 'MC', 'MD', 'ME', 'MK', 'NO', 'RS', 'SM', 'TR', 'UA', 'VA'];
    t.regionNotNeeded = ['AR', 'AT', 'BE', 'DE', 'DK', 'EE', 'ES', 'FI', 'FJ', 'FR', 'GB', 'GL', 'HK', 'ID', 'IE', 'IL', 'IN', 'IS', 'LU', 'NL', 'NO', 'NZ', 'OM', 'PK', 'PL', 'SE', 'SG', 'TW', 'UA', 'ZA'];
    t.postcodeNeeded = ['US', 'CA'];
    t.postcodeNotNeeded = ['AE', 'AG', 'AO', 'AW', 'BF', 'BI', 'BJ', 'BO', 'BQ', 'BS', 'BW', 'BZ', 'CD', 'CF', 'CG', 'CI', 'CK', 'CM', 'CW', 'DJ', 'DM', 'ER', 'FJ', 'GA', 'GD', 'GH', 'GM', 'GQ', 'GY', 'HK', 'HM', 'IE', 'JM', 'KI', 'KM', 'KN', 'KP', 'LC', 'LR', 'LY', 'ML', 'MO', 'MR', 'MW', 'PA', 'QA', 'RW', 'SB', 'SC', 'SL', 'SO', 'SR', 'SS', 'ST', 'SX', 'SY', 'TD', 'TF', 'TG', 'TK', 'TL', 'TO', 'TV', 'TZ', 'UG', 'UM', 'VU', 'YE', 'ZM', 'ZW'];

    t.postcodeRegExs = {
        AI: {
            label: 'AI-2640',
            exp: '^AI*(-|—|–| )?2640$'
        },
        AS: {
            label: 'AS 96799',
            exp: '^AS ?96799$'
        },
        AU: {
            label: '9999',
            exp: '^[0-9]{4}$'
        },
        AT: {
            label: '9999',
            exp: '^[0-9]{4}$'
        },
        BE: {
            label: '9999',
            exp: '^[0-9]{4}$'
        },
        BG: {
            label: '9999',
            exp: '^[0-9]{4}$'
        },
        CA: {
            label: 'A0A 0A0',
            exp: '^[A-z][0-9][A-z] ?[0-9][A-z][0-9]$'
        },
        CC: {
            label: '6799',
            exp: '^6799$'
        },
        CX: {
            label: '6798',
            exp: '^6798$'
        },
        CZ: {
            label: '999 99',
            exp: '^[0-9]{3} ?[0-9]{2}$'
        },
        DE: {
            label: '99999',
            exp: '^[0-9]{5}$'
        },
        DK: {
            label: '999 or 9999',
            exp: '^[0-9]{3,4}$'
        },
        ES: {
            label: '01999 or 50999',
            exp: '^((0[1-9]|5[0-2])|[1-4][0-9])[0-9]{3}$'
        },
        FI: {
            label: '99999',
            exp: '^[0-9]{5}$'
        },
        FK: {
            label: 'F1QQ 1ZZ',
            exp: '^F1QQ ?1ZZ$'
        },
        FR: {
            label: '99999',
            exp: '^[0-9]{5}$'
        },
        GB: {
            label: 'AA9 9AA',
            exp: '^[A-z]{1,2}[0-9Rr][0-9A-z]? ?[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$'
        },
        GI: {
            label: 'GX11 1AA',
            exp: '^GX11 ?1AA$'
        },
        GR: {
            label: '999 99',
            exp: '^[0-9]{3} ?[0-9]{2}$'
        },
        GS: {
            label: 'SIQQ 1ZZ',
            exp: '^SIQQ ?1ZZ$'
        },
        HR: {
            label: '99999',
            exp: '^[0-9]{5}$'
        },
        HU: {
            label: '9999',
            exp: '^[0-9]{4}$'
        },
        IE: {
            label: 'A99 AA99',
            exp: '^[AC-FHKNPRTV-Y]{1}[0-9]{1}[0-9W]{1}[ -]?[0-9AC-FHKNPRTV-Y]{4}$'
        },
        IO: {
            label: 'BBND 1ZZ',
            exp: '^BBND ?1ZZ$'
        },
        IT: {
            label: '99999',
            exp: '^[0-9]{5}$'
        },
        LU: {
            label: 'L-9999',
            exp: '^(L(-|—|–))?[0-9]{4}$'
        },
        LV: {
            label: 'LV-9999',
            exp: '^LV(-|—|–)[0-9]{4}$'
        },
        MT: {
            label: 'AAA 9999',
            exp: '^[A-z]{3} ?[0-9]{4}$'
        },
        NF: {
            label: '2899',
            exp: '^2899$'
        },
        NL: {
            label: '999 AA',
            exp: '^[1-9][0-9]{2,3} ?[A-z]{2}$'
        },
        NR: {
            label: 'NRU68',
            exp: '^NRU68$'
        },
        NU: {
            label: '9974',
            exp: '^9974$'
        },
        PL: {
            label: '99-999',
            exp: '^[0-9]{2}(-|—|–)[0-9]{3}$'
        },
        PT: {
            label: '9999-999',
            exp: '^[0-9]{4}(-|—|–)[0-9]{3}$'
        },
        PW: {
            label: '96940',
            exp: '^96940$'
        },
        RO: {
            label: '999999',
            exp: '^[0-9]{6}$'
        },
        SE: {
            label: '999 99',
            exp: '^[0-9]{3} ?[0-9]{2}$'
        },
        SI: {
            label: '9999',
            exp: '^[0-9]{4}$'
        },
        SK: {
            label: '999?99',
            exp: '^[0-9]{3} ?[0-9]{2}$'
        },
        TC: {
            label: 'TKCA 1ZZ',
            exp: '^TKCA ?1ZZ$'
        },
        US: {
            label: '99999',
            exp: '^[0-9]{5}(?:-[0-9]{4})?$'
        },
        VA: {
            label: '00120',
            exp: '^00120$'
        }
    };
    t.getRegionByPostcode = function(country, postcode, vm, fieldList) {
        $.post(t.getRegionURL, {
            country: country,
            postcode: postcode
        }).fail(function(result) {
            //handle errors
            var err = new Error('unexpected AJAX error: ' + result);
            if (typeof(newrelic) !== 'undefined') {
                newrelic.noticeError(err);
            }
        }).done(function(result) {
            if (result.valid) {
                // sometimes there is no city
                var city = result.data.city || result.data.raw.administrative_area_level_3.short_name,
                    region = result.data.postal_town || result.data.state;
                vm[fieldList.cityField](city);
                vm[fieldList.stateField](region);
            } else {
                //handle errors
                var err = new Error('unexpected AJAX error: location not found');
                if (typeof(newrelic) !== 'undefined') {
                    newrelic.noticeError(err);
                }
                return false;
            }
        });
    };
    t.parsePhone = function(phoneString, country) {
        var parsedNum, success = true;
        try {
            parsedNum = libphonenumber.parsePhoneNumber(phoneString);
        } catch (e) {
            try {
                parsedNum = libphonenumber.parsePhoneNumber("+" + phoneString);
            } catch (e) {
                try {
                    parsedNum = libphonenumber.parsePhoneNumber(phoneString, country);
                } catch (e) {
                    success = false;
                }
            }
        }
        var returning = {
            "success": success
        };
        if (!success) {
            return returning;
        }
        returning.numberInfo = {
            "country": parsedNum.country,
            "countryCode": parsedNum.countryCallingCode,
            "nationalPhoneNumber": parsedNum.nationalNumber
        };
        return returning;
    };
    ko.validation.rules.postalCode = {
        getValue: function(o) {
            return (typeof o === 'function' ? o() : o);
        },
        validator: function(val, countryValue) {
            if (ko.validation.utils.isEmptyVal(val)) {
                return true;
            }
            var regex = (this.getValue(countryValue) in t.postcodeRegExs) ? t.postcodeRegExs[this.getValue(countryValue)].exp : '[0-9A-z -]*';
            return val.match(regex);
        },
        message: 'Invalid postal code'
    };
    ko.validation.rules.phoneNumber = {
        getValue: function(o) {
            return (typeof o === 'function' ? o() : o);
        },
        validator: function(val, options) {
            var number = val;
            if (typeof(options) !== "undefined" && options.prefix) {
                number = this.getValue(options.prefix) + val;
            }
            return libphonenumber.isPossiblePhoneNumber(number);
        },
        message: 'Invalid phone number'
    };
    ko.validation.registerExtenders();
    t.init = function() {};
    return t;
})(tindie.addresses || {}, jQuery);